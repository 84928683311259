<template>
  <div id="user-profile">
    <user-header />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <prayer-nav />
      </div>
    </b-card>
    <b-row class="mt-2 justify-content-center">
      <b-card>
        <b-row>
          <b-col
            cols="12"
            class="m-2"
          >
            <b-card-text>
              <h4>
                Update Iqama
              </h4>
            </b-card-text>
          </b-col>
          <!-- From -->
          <b-col cols="6">
            <b-form-group
              label="From"
              label-for="search"
            >
              <b-form-datepicker
                id="from"
                v-model="cityData.from"
                class="mb-1"
              />
            </b-form-group>
          </b-col>

          <!-- To -->
          <b-col cols="6">
            <b-form-group
              label="To"
              label-for="search"
            >
              <b-form-datepicker
                id="to"
                v-model="cityData.to"
                class="mb-1"
              />
            </b-form-group>
          </b-col>

          <!-- Prayer -->
          <b-col cols="6">
            <b-form-group
              label="Prayer"
              label-for="classification"
            >
              <b-form-select
                v-model="cityData.prayers"
                :options="prayers"
                value-field="id"
                text-field="name"
                disabled-field="notEnabled"
              />
            </b-form-group>
          </b-col>

          <!-- time -->
          <b-col
            cols="6"
          >
            <b-form-group
              label="Iqama Time"
              label-for="time"
            >
              <b-form-timepicker
                v-model="cityData.time"
                locale="en"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="md"
              variant="primary"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, VBTooltip, BFormGroup, BFormDatepicker, BCardText, BFormSelect, BFormTimepicker,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import UserHeader from './UserHeader.vue'
import prayerNav from './Nav.vue'

export default {
  components: {
    BCard,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormDatepicker,
    UserHeader,
    prayerNav,
    BCardText,
    BFormTimepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      cityData: {
        from: '',
        to: '',
        time: '',
        prayers: [],
      },
      prayers: [],
      options: {
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
      },
    }
  },
}
</script>

<style lang="scss" >
.form-control {
    border: 1px solid #d8d6de;
}

@import '@core/scss/vue/pages/page-profile.scss';

</style>
